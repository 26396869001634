import React from "react";
import "./footer.css";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faTiktok,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";

const Index = () => {
  const insta = (
    <svg
      width="20"
      height="20"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.7619 1.02393C3.14512 1.02393 1.0238 3.14524 1.0238 5.76202V16.2382C1.0238 18.855 3.14512 20.9763 5.7619 20.9763H16.2381C18.8549 20.9763 20.9762 18.855 20.9762 16.2382V5.76202C20.9762 3.14524 18.8549 1.02393 16.2381 1.02393H5.7619ZM0.0238037 5.76202C0.0238037 2.59296 2.59284 0.0239258 5.7619 0.0239258H16.2381C19.4072 0.0239258 21.9762 2.59296 21.9762 5.76202V16.2382C21.9762 19.4073 19.4072 21.9763 16.2381 21.9763H5.7619C2.59284 21.9763 0.0238037 19.4073 0.0238037 16.2382V5.76202ZM11.5866 7.30404C10.8188 7.19017 10.0346 7.32133 9.34559 7.67884C8.65658 8.03636 8.09785 8.60203 7.74886 9.29539C7.39986 9.98876 7.27839 10.7745 7.40171 11.5409C7.52503 12.3073 7.88687 13.0153 8.43576 13.5642C8.98464 14.113 9.69263 14.4749 10.459 14.5982C11.2254 14.7215 12.0112 14.6 12.7045 14.2511C13.3979 13.9021 13.9636 13.3433 14.3211 12.6543C14.6786 11.9653 14.8097 11.1811 14.6959 10.4133C14.5797 9.63003 14.2148 8.90492 13.6549 8.34503C13.095 7.78515 12.3699 7.42018 11.5866 7.30404ZM8.88502 6.79122C9.76073 6.33683 10.7574 6.17014 11.7333 6.31485C12.7288 6.46247 13.6504 6.92633 14.362 7.63793C15.0736 8.34952 15.5374 9.27112 15.6851 10.2666C15.8298 11.2425 15.6631 12.2392 15.2087 13.1149C14.7543 13.9906 14.0354 14.7007 13.1541 15.1443C12.2729 15.5878 11.2742 15.7422 10.3001 15.5855C9.32609 15.4288 8.42627 14.9689 7.72865 14.2713C7.03103 13.5736 6.57115 12.6738 6.41441 11.6998C6.25767 10.7257 6.41206 9.72705 6.85562 8.8458C7.29918 7.96456 8.00931 7.24561 8.88502 6.79122ZM16.7619 4.73821C16.4858 4.73821 16.2619 4.96207 16.2619 5.23821C16.2619 5.51435 16.4858 5.73821 16.7619 5.73821H16.7719C17.048 5.73821 17.2719 5.51435 17.2719 5.23821C17.2719 4.96207 17.048 4.73821 16.7719 4.73821H16.7619Z"
        fill="Black"
      />
    </svg>
  );
  const fb = (
    <svg
      width="20"
      height="20"
      viewBox="0 0 14 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13 1H9.72727C8.28064 1 6.89325 1.57946 5.87033 2.61091C4.8474 3.64236 4.27273 5.04131 4.27273 6.5V9.8H1V14.2H4.27273V23H8.63636V14.2H11.9091L13 9.8H8.63636V6.5C8.63636 6.20826 8.7513 5.92847 8.95588 5.72218C9.16047 5.51589 9.43795 5.4 9.72727 5.4H13V1Z"
        stroke="black"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
  const linkedin = (
    <svg
      width="20"
      height="20"
      viewBox="0 0 23 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.04761 2.7498C1.04761 1.80887 1.8065 1.0498 2.73808 1.0498C3.66967 1.0498 4.42856 1.80887 4.42856 2.7498C4.42856 3.69074 3.66967 4.4498 2.73808 4.4498C1.8065 4.4498 1.04761 3.69074 1.04761 2.7498ZM2.73808 0.0498047C1.25013 0.0498047 0.0476074 1.26068 0.0476074 2.7498C0.0476074 4.23893 1.25013 5.4498 2.73808 5.4498C4.22604 5.4498 5.42856 4.23893 5.42856 2.7498C5.42856 1.26068 4.22604 0.0498047 2.73808 0.0498047ZM15.8809 7.6498C14.2714 7.6498 12.7273 8.29195 11.5886 9.43569C10.4497 10.5795 9.80951 12.1313 9.80951 13.7498V20.9498H13.1905V13.7498C13.1905 13.0344 13.4734 12.3479 13.9777 11.8414C14.4821 11.3348 15.1667 11.0498 15.8809 11.0498C16.5952 11.0498 17.2798 11.3348 17.7842 11.8414C18.2885 12.3479 18.5714 13.0344 18.5714 13.7498V20.9498H21.9524V13.7498C21.9524 12.1313 21.3122 10.5795 20.1733 9.43569C19.0345 8.29195 17.4905 7.6498 15.8809 7.6498ZM15.8809 6.6498C14.0048 6.6498 12.2059 7.39837 10.8799 8.73011C9.55401 10.0618 8.80951 11.8675 8.80951 13.7498V21.4498C8.80951 21.7259 9.03337 21.9498 9.30951 21.9498H13.6905C13.9666 21.9498 14.1905 21.7259 14.1905 21.4498V13.7498C14.1905 13.2982 14.3691 12.8656 14.6864 12.547C15.0036 12.2284 15.4333 12.0498 15.8809 12.0498C16.3286 12.0498 16.7583 12.2284 17.0755 12.547C17.3928 12.8656 17.5714 13.2982 17.5714 13.7498V21.4498C17.5714 21.7259 17.7953 21.9498 18.0714 21.9498H22.4524C22.7285 21.9498 22.9524 21.7259 22.9524 21.4498V13.7498C22.9524 11.8675 22.2079 10.0618 20.882 8.73011C19.556 7.39837 17.7571 6.6498 15.8809 6.6498ZM0.0476074 8.2498C0.0476074 7.97366 0.271465 7.7498 0.547607 7.7498H4.92856C5.2047 7.7498 5.42856 7.97366 5.42856 8.2498V21.4498C5.42856 21.7259 5.2047 21.9498 4.92856 21.9498H0.547607C0.271465 21.9498 0.0476074 21.7259 0.0476074 21.4498V8.2498ZM1.04761 8.7498V20.9498H4.42856V8.7498H1.04761Z"
        fill="black"
      />
    </svg>
  );
  const twitter = (
    <svg
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M23 2.9998C22.0424 3.67528 20.9821 4.19191 19.86 4.5298C19.2577 3.83731 18.4573 3.34649 17.567 3.12373C16.6767 2.90096 15.7395 2.957 14.8821 3.28426C14.0247 3.61151 13.2884 4.1942 12.773 4.95352C12.2575 5.71283 11.9877 6.61214 12 7.5298V8.5298C10.2426 8.57537 8.50127 8.18561 6.93101 7.39525C5.36074 6.60488 4.01032 5.43844 3 3.9998C3 3.9998 -1 12.9998 8 16.9998C5.94053 18.3978 3.48716 19.0987 1 18.9998C10 23.9998 21 18.9998 21 7.4998C20.9991 7.22126 20.9723 6.9434 20.92 6.6698C21.9406 5.6633 22.6608 4.39251 23 2.9998V2.9998Z"
        stroke="black"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
  return (
    <div className="main_footer">
      <div className="sub_footer">
        <div className="F_content_div">
          <div className="trem_and_privacy_div">
            <span>© 2024 All Rights Reserved</span>
            {/* <span>Privcy & policy</span> */}
            {/* <span>|</span> */}
            {/* <span>Trem and condition </span> */}
          </div>
          <div className="social_icon_div">
            <Link
              className="links"
              to="https://www.facebook.com/inspirehealthandwellnessapp"
              target="_blank">
              <FontAwesomeIcon className="icons" icon={faFacebookF} />
            </Link>
            <Link
              className="links"
              to="https://www.tiktok.com/@inspirewellnessapp?lang=en"
              target="_blank">
              <FontAwesomeIcon className="icons" icon={faTiktok} />
            </Link>
            <Link
              className="links"
              to="https://www.instagram.com/inspirewellnessapp/?igsh=MTZta3htMHVwcG80dA%3D%3D&utm_source=qr"
              target="_blank">
              <FontAwesomeIcon className="icons" icon={faInstagram} />
            </Link>

            {/* <span>{insta}</span>
            <span>{fb}</span>
            <span>{linkedin}</span>
            <span>{twitter}</span> */}
          </div>
        </div>
        <div className="last_div">
          {/* <span>© 2024 All Rights Reserved</span> */}
        </div>
      </div>
    </div>
  );
};

export default Index;
