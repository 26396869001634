import { Route, Routes } from "react-router-dom";
import "./App.css";
import Mainpage from "./pages/MainPage/index.jsx";
import Thankyou from "./pages/thankyou/index.jsx";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Mainpage />} />
        <Route path="/Thankyou" element={<Thankyou />} />
      </Routes>
    </div>
  );
}

export default App;
