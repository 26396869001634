import React from "react";
import "./newse.css";

const Index = () => {
  return (
    <div className="new_main_div">
      <div className="new_sub_div">
        <h1>Meet Inspire Wellness</h1>

        <div className="ABout_txt_sub">
          <div className="about_us_text">
            <h2>One of the holistic health community’s best wellness apps.</h2>
            <p>Like you, we know what it’s like to be busy.</p>
            <p>
              We understand what it’s like to feel like you’re falling behind on
              your goals, not taking care of your body, or neglecting your
              mental health. Everyone tells you everything you need to be doing
              to take care — but where can you actually start?
            </p>
            <p>
              It all starts with the Japanese philosophy, Kaizen: Small, daily
              steps that lead to a greater transformation.
            </p>
            <p>
              Inspire is your personal companion, assistant, and support to
              experience this transformation, one day — one minute — at a time.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
