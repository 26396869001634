import React from "react";
import "./Sec4.css";
import howitworkimg from "../../../../assets/Howitwork.png";
import sec4 from "../../../../assets/Sec-4.mp4";

const Index = () => {
  const start = (
    <svg
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_1246_11838)">
        <path
          d="M23.9372 9.20284C23.7792 8.71687 23.3482 8.37281 22.8402 8.32685L15.9121 7.69787L13.1741 1.28761C12.972 0.816655 12.512 0.512695 12 0.512695C11.4881 0.512695 11.0279 0.816655 10.827 1.28761L8.08902 7.69787L1.15982 8.32685C0.651881 8.37373 0.221759 8.71779 0.0628208 9.20284C-0.0952019 9.68881 0.0507355 10.2218 0.434897 10.5588L5.67198 15.1509L4.12783 21.952C4.01485 22.452 4.20895 22.9691 4.62387 23.2691C4.8469 23.4311 5.10893 23.512 5.37187 23.512C5.59783 23.512 5.82396 23.452 6.02593 23.3311L12 19.7591L17.973 23.3311C18.4112 23.593 18.9622 23.569 19.3762 23.2691C19.7911 22.9691 19.9852 22.452 19.8722 21.952L18.3281 15.1509L23.5652 10.5588C23.9491 10.2218 24.0953 9.68991 23.9372 9.20284Z"
          fill="#3A4F39"
        />
      </g>
      <defs>
        <clipPath id="clip0_1246_11838">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0 -0.00292969)"
          />
        </clipPath>
      </defs>
    </svg>
  );

  const Sec4data = [
    {
      id: 1,
      titel: "Daily Wellness Activities",
      discripiton:
        "Make wellness a part of your everyday life. Choose from a variety of self-care activities, from fitness to meditation. Take just 1 minute or longer — you decide.",
    },
    {
      id: 2,
      titel: "Personal Wellness & Calendar Integration",
      discripiton:
        "Need a gentle push? Inspire will act as your accountability partner and personal assistant. Connect your calendar to get reminders and notifications about your daily activities, wellness-related or not.",
    },
    {
      id: 3,
      titel: "Gratitude & Wellness Journal",
      discripiton:
        "Fall in love with your life. Train your brain to see the beauty around you with your wellness journal. Complete quick, daily journaling prompts with a gratitude section.",
    },
    {
      id: 4,
      titel: "Daily Wellness Activities:",
      discripiton:
        "Incorporate wellness into your daily routine with timed activities designed to fit into even the busiest schedules. Whether it's a quick one-minute mindfulness exercise or a longer relaxation session, there's something for everyone to enjoy.",
    },
    {
      id: 5,
      titel: "Personalized Self-Care Planners",
      discripiton:
        "Vision boards, brain dumps, to-do lists, family planners, finance tracking, shopping lists, fitness planners, mind maps — you name it. Get organized and reach your goals with a variety of planners.",
    },
    {
      id: 6,
      titel: "Daily 1-Minute Habit Stackers",
      discripiton:
        "Turn health into a daily habit. Use the proven habit-stacking technique to build your dream wellness routine: All in just 1 minute each day.",
    },
    {
      id: 7,
      titel: "Fitness & Yoga Programs",
      discripiton:
        "Your fitness goals, met. Whether you want to build muscle, lose weight, or relax your body, you can find guided exercises and programs that meet you where you’re at — and help you get where you want to go.",
    },
    {
      id: 8,
      titel: "Soundscapes, Sleep Stories, & Relaxation Music",
      discripiton:
        "Inspire is there to keep you well from morning to night. Relax, unwind from stress, and sleep better with soothing soundscapes, sleep stories, and soft music.",
    },
    {
      id: 9,
      titel: "Well in 1 Podcast",
      discripiton:
        "Listen and learn with our podcast series on how to live your best life, even with the busiest of schedules. Tune in whenever, wherever",
    },
    {
      id: 10,
      titel: "Wellness Challenges",
      discripiton:
        "Inspire is a community, and you get to be a part of it. Participate in exciting wellness challenges to stay motivated and make self-care something you look forward to.",
    },
  ];

  return (
    <div className="Sec4_main_div" id="Sec4">
      <div className="Sec4_sub_div">
        <h1>Inspire Wellness: Everything You Need, All in One App.</h1>
        <div className="content_div">
          <div className="cotent_text_div">
            {Sec4data.slice(0, 5).map((item, index) => {
              return (
                <div className="contect_innetr_text_idv" key={index}>
                  <span>{start}</span>
                  <div className="text_sec4_inner">
                    <h2>{item.titel}</h2>
                    <p>{item.discripiton}</p>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="content_img">
            <video src={sec4} controls autoPlay></video>
            {/* <img src={howitworkimg} alt="" /> */}
          </div>
          <div className="cotent_text_div">
            {Sec4data.slice(5).map((item, index) => {
              return (
                <div className="contect_innetr_text_idv" key={index + 5}>
                  <span>{start}</span>
                  <div className="text_sec4_inner">
                    <h2>{item.titel}</h2>
                    <p>{item.discripiton}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
