import React from "react";
import "./Sec5.css";
import Download from "../../../../assets/Download.png";

const Index = () => {
  return (
    <div className="Sec5_main_div" id="Sec5">
      <div className="Sec5_sub_div">
        <h1>Meet Inspire Wellness</h1>
        <div className="sub_Sec5_div">
          <div className="sec5_text_main_div">
            <h3>Download Inspire Wellness.</h3>
            <ul>
              <li>Release your stress.</li>
              <li>Revive your fitness.</li>
              <li>Renew your mindfulness.</li>
              <li>Reach your goals. </li>
              <li>Reorganize your life</li>
              <li>Remember your self-care needs.</li>
            </ul>
            <p>All with the help of one app</p>
            <button className="mainbutton">Download</button>
          </div>
          <div className="Sec5_img_div">
            <img src={Download} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
