import axios from "axios";

const ApiUrl = process.env.REACT_APP_API_KEY;
// console.log(
//   "🚀 ~ file: index.js:4 ~ process.env.REACT_APP_API_KEY:",
//   process.env.REACT_APP_API_KEY
// );
// console.log("🚀 ~ file: index.js:4 ~ ApiUrl", ApiUrl);

const Api = axios.create({
  baseURL: ApiUrl,
});

export default Api;
