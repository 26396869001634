import { useState, useEffect, useRef } from "react";
import "./nav.css";
// import logo from "../../../assets/logo.jpeg";
import logo2 from "../../../assets/Logo2.jpeg";
import logo from "../../../assets/logo.png";
// import logo2 from "../../../assets/Logo2.png";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { Fade as Hamburger } from "hamburger-react";
import { Link } from "react-scroll";

const Index = () => {
  const [screenSize, setScreenSize] = useState(getCurrentDimension());
  const [lang, setlang] = useState();

  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }
  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener("resize", updateDimension);

    return () => {
      window.removeEventListener("resize", updateDimension);
    };
  }, [screenSize]);
  const Navigate = useNavigate();

  let menuref = useRef();

  const [open, setIsopen] = useState(false);
  const [onshow, setonshow] = useState(false);

  useEffect(() => {
    let handler = (event) => {
      if (menuref.current) {
        if (!menuref.current.contains(event.target)) {
          setIsopen(false);
          setonshow(false);
          // setshow(false);
        }
      }
    };
    document.addEventListener("mousedown", handler);
    document.addEventListener("scroll", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
      document.removeEventListener("scroll", handler);
    };
  }, []);
  const toggle = (item) => {
    setIsopen(false);
  };
  const handlehide = () => {
    setIsopen(!open);
  };
  const pathname = useLocation();
  useEffect(() => {
    setlang(pathname.pathname);
  }, [pathname]);

  return (
    <div ref={menuref} className="Main_navbar">
      <div className="mainnav">
        <nav id="navbar">
          <Link to="Sec1" className="logo">
            <img src={logo} alt="" />
          </Link>
          <Link to="Sec1" className="logo2">
            <img src={logo2} alt="" />
          </Link>

          <ul className={open ? "navlinks mobilemenu" : "navlinks"}>
            <li>
              <Link to="Sec1" smooth={true} onClick={() => toggle("")}>
                Home
              </Link>
            </li>
            <li>
              <Link
                to="Sec2"
                offset={-100}
                smooth={true}
                onClick={() => toggle("")}>
                About Us
              </Link>
            </li>
            {/* <li onMouseMove={() => setonshow(true)}>
              <Link to="Sec2" offset={-100} onClick={() => toggle("")}>
                Case Studies
              </Link>
            </li> */}
            {/* <li>
              <Link
                to="Sec3"
                offset={-100}
                smooth={true}
                onClick={() => toggle("")}>
                Blog
              </Link>
            </li> */}

            <li>
              <Link
                to="Sec4"
                smooth={true}
                offset={-100}
                onClick={() => toggle("")}>
                How it Works
              </Link>
            </li>

            <li>
              <Link
                to="Contact"
                smooth={true}
                offset={-100}
                onClick={() => toggle("")}>
                Contact Us
              </Link>
            </li>
            <button className="download_btn">
              <Link to="Sec5" smooth={true} offset={-100}>
                Download Now
              </Link>
            </button>
          </ul>

          <div className="menubtn">
            <Hamburger onToggle={handlehide} toggled={open} />
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Index;
