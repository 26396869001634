import React from "react";
import "./Sec2.css";
const Index = () => {
  return (
    <div id="Sec2" className="Sec2_main_div">
      <div className="Sec2_sub_div">
        <h1>Inspire Wellness in as Little as 1 Minute.</h1>

        <div className="ABout_txt_sub">
          <div className="about_us_text">
            <h2>Timed Wellness Activities for Your Busy Lifestyle.</h2>
            <p>
              Don’t have time for self-care? That all changes with Inspire. We
              know you’re busy, which is why we’ve developed a library of daily
              activities you can choose from: Starting at just 1 minute. Squeeze
              in a moment of relaxation, any time, any where.
            </p>
          </div>
          <div className="about_us_text">
            <h2>Everything Wellness, All in One Place.</h2>
            <p>
              No need for multiple fitness apps, reminder apps, meditation apps,
              or self-improvement apps — find all the features you love from the
              best wellness apps and all in one place. With Inspire Wellness,
              you prioritize your mental, emotional, and physical health.
            </p>
          </div>
          <div className="about_us_text">
            <h2>Take Small, Daily Steps for Lasting Change.</h2>
            <p>
              You don’t have to do it all now. We believe in the Japanese
              philosophy of Kaizen: Real change starts with small, incremental
              steps. Inspire is rooted in this philosophy, making it easy for
              you to finally stick to your wellness goals — and meet them.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
