import React from "react";
import "./Sec1.css";

const Index = () => {
  return (
    <div className="Sec1_main_div" id="Sec1">
      <div className="Sec1_sub_div">
        <div className="DIV"></div>
        <div className="text_Div">
          <h1>
            Meet <span>INSPIRE</span>: Your Personal Wellness Assistant
          </h1>
          <h5>All the Features of the Best Wellness Apps, All in One Place.</h5>
          <p>
            Wellness that fits your schedule, no matter where you are, what you
            need, and how much time you have.
          </p>
          <p>
            Turn your goals into habits with Inspire Wellness: The all-in-one
            wellness app with daily timed wellness activities, programs, and
            planners for your mind, body, and spirit.Wellness that fits your
            schedule, no matter where you are, what you need, and how much time
            you have.
          </p>
          <button className="mainbutton">Download Now</button>
        </div>
      </div>
    </div>
  );
};

export default Index;
