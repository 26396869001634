import { useState } from "react";
import "./Sec6.css";
import { ContactusSchemas } from "../../../../schemas";
import { useFormik } from "formik";
import axios from "../../../../Comman/Api";
import { useNavigate, useLocation } from "react-router-dom";
import { Backdrop, CircularProgress } from "@mui/material";

const Index = () => {
  const [loading, setloading] = useState(false);
  const initialValues = {
    FullName: "",
    LastName: "",
    Email: "",
    // Number: "",
    Message: "",
  };
  const navigate = useNavigate();
  // const onSubmit = (values, { resetForm }) => {
  //   console.log(values);
  //   navigate("/Thankyou");
  //   resetForm();
  // };
  const onSubmit = async (values, { resetForm }) => {
    setloading(true);
    try {
      const response = await axios.post("/contact-us", {
        firstName: values.FullName,
        lastName: values.LastName,
        email: values.Email,
        // mobile: values.Number,
        message: values.Message,
      });
      console.log("response ===> ", response);
      setloading(false);
      if (response.status === 200) {
        resetForm();
        navigate("/Thankyou");
      }
    } catch (error) {
      console.log("error====>", error);
    }
  };
  const {
    values,
    handleBlur,
    handleChange,
    touched,
    handleSubmit,
    errors,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: ContactusSchemas,
    onSubmit: onSubmit,
    // onSubmit,
  });
  return (
    <div className="Sec6_main_div" id="Contact">
      <div className="Sec6_sub_div">
        <h1>Contact Us</h1>
        <div className="main_form_div">
          <form onSubmit={handleSubmit} className="form_box">
            <div className="name_div_main">
              <div className="fname_div">
                <label htmlFor="text">First Name</label>
                <div className="fname_input_div">
                  <input
                    type="FullName"
                    value={values.FullName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="FullName"
                    id=""
                    placeholder="First Name"
                  />
                </div>
                {errors.FullName && touched.FullName ? (
                  <p className="errors_msg_p">{errors.FullName} </p>
                ) : null}
              </div>
              <div className="fname_div">
                <label htmlFor="text">Last Name</label>
                <div className="fname_input_div">
                  <input
                    type="LastName"
                    name="LastName"
                    value={values.LastName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id=""
                    placeholder="Last Name"
                  />
                </div>
                {errors.LastName && touched.LastName ? (
                  <p className="errors_msg_p">{errors.LastName} </p>
                ) : null}
              </div>
            </div>
            <div className="name_div_main" style={{ alignItems: "flex-start" }}>
              <div className="fname_div">
                <label htmlFor="text">Email</label>
                <div className="fname_input_div">
                  <input
                    type="Email"
                    name="Email"
                    value={values.Email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id=""
                    placeholder="tim.jennings@example.com"
                  />
                </div>
                {errors.Email && touched.Email ? (
                  <p className="errors_msg_p">{errors.Email} </p>
                ) : null}
              </div>
              {/* <div className="fname_div">
                <label htmlFor="text">Mobile Number</label>
                <div className="fname_input_div">
                  <input
                    type="number"
                    name="Number"
                    value={values.Number}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="99889 99889"
                  />
                </div>
                {errors.Number && touched.Number ? (
                  <p className="errors_msg_p">{errors.Number} </p>
                ) : null}
              </div> */}
            </div>
            <div className="text_input_div">
              <label htmlFor="text">Message</label>
              <div className="text_area_input_box">
                <textarea
                  name="Message"
                  type="Message"
                  value={values.Message}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Write here"></textarea>
              </div>
              {errors.Message && touched.Message ? (
                <p className="errors_msg_p">{errors.Message} </p>
              ) : null}
            </div>
            <button type="Submit" className="mainbutton">
              Submit
            </button>
          </form>
        </div>
        {!loading && <div></div>}
        {loading && (
          <div>
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={true}>
              <CircularProgress color="inherit" />
            </Backdrop>
          </div>
        )}
      </div>
    </div>
  );
};

export default Index;
